<template>
    <div>
        <h1>Order Progress</h1>
        <form @submit.prevent="runReport">

            <Options />
            <RptNav/>
            <Result
                ref="'slot1'"
                :goalData="state.jsonResult.slot1.data"
            />

        </form>
    </div>
</template>

<script>
    import { store } from "@/store/Report.store.js";
    import Options from "./sales_goals_opt";
    import Result from "@/components/reports/OrderProgress/Result";
    import RptNav from "@/components/reports/utils/RptNav";

    export default {
        name: 'sales_goals',
        components: {
            RptNav,
            Options,
            Result
        },
        data() {
            return {
                state: store.state,
                tabView: 'report',
                optionFields: {
                    month: {val: new Date().getMonth() + 1, qsField: '[month]'},
                    year: {val: new Date().getFullYear(), qsField: '[year]'},
                    cdiLocation: {val: '', qsField: '[show_location_goals]'},
                    includeCdiStock: {val: '', qsField: '[include_cdi_stock]'},
                },
                groupByFields: {},
                dataFields: {},
                comparisonRptVisible: false
            }
        },
        props: ['action'],
        created() {
            this.$appStore.setTitle(['Order Progress']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields, true);
        },
        mounted() {

        },
        watch: {
            action: function() {
                if (this.action === 'runReport') {
                    this.runReport();
                    store.resetTabAction();
                }
            }
        }
    }
</script>

