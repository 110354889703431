<template>
    <div>
        <div v-if="goalData">
            <div v-if="!goalData.length">
                <table>
                    <tr>
                        <th>No Results Found.</th>
                    </tr>
                </table>
            </div>
            <div v-else>
            <table class="table table-responsive">
                <thead>
                <tr>
                    <th>Date</th>
                    <th class="right">Daily Orders</th>
                    <th class="right">Daily Goal</th>
                    <th class="right">Orders/MTD</th>
                    <th class="right">Goal/MTD</th>
                </tr>
                </thead>
                <tbody v-if="!isLocation()">
                <tr v-for="(goal, index1) in goalData" :key="'index1'+ index1">
                    <td>{{ goal.date }}</td>
                    <td class="right" :class="(parseInt(goal.day_sales) < goal.day_goal) ? 'bg-danger': ''">
                        {{ goal.day_sales | currency }}
                    </td>
                    <td class="right">{{ goal.day_goal | currency }}</td>
                    <td class="right"
                        :class="(parseInt(goal.cumulative_total_sales) < goal.cumulative_goal) ? 'bg-danger': ''">
                        {{ goal.cumulative_total_sales | currency }}
                    </td>
                    <td class="right">{{ goal.cumulative_goal | currency }}</td>
                </tr>
                <tr>
                    <th class="bold">Totals:</th>
                    <th class="right">$0.00</th>
                    <th class="right">$0.00</th>
                    <th class="right">{{ lastItem.cumulative_total_sales | currency }}</th>
                    <th class="right">{{ totalGoalMtd | currency }}</th>
                </tr>
                </tbody>
                <tbody v-if="isLocation()">
                <tr v-for="(goal, index2) in goalData" :key="'index2'+ index2">
                    <th v-if="!goal.is_location">{{ goal.date }}</th>
                    <td v-if="goal.is_location">{{ goal.is_location }}</td>
                    <th v-if="!goal.is_location" class="right"
                        :class="(parseInt(goal.day_sales) < goal.day_goal) ? 'bg-danger': ''">
                        {{ goal.day_sales | currency }}
                    </th>
                    <td v-if="goal.is_location" class="right"
                        :class="(parseInt(goal.day_sales) < goal.day_goal) ? 'bg-danger': ''">
                        {{ goal.day_sales | currency }}
                    </td>
                    <th v-if="!goal.is_location" class="right">{{ goal.day_goal | currency }}</th>
                    <td v-if="goal.is_location" class="right">{{ goal.day_goal | currency }}</td>
                    <th v-if="!goal.is_location" class="right"
                        :class="(parseInt(goal.cumulative_total_sales) < goal.cumulative_goal) ? 'bg-danger': ''">
                        {{ goal.cumulative_total_sales | currency }}
                    </th>
                    <td v-if="goal.is_location" class="right"
                        :class="(parseInt(goal.cumulative_total_sales) < goal.cumulative_goal) ? 'bg-danger': ''">
                        {{ goal.cumulative_total_sales | currency }}
                    </td>
                    <th v-if="!goal.is_location" class="right">{{ goal.cumulative_goal | currency }}</th>
                    <td v-if="goal.is_location" class="right">{{ goal.cumulative_goal | currency }}</td>
                </tr>
                <tr>
                    <th class="bold">Totals:</th>
                    <th class="right">$0.00</th>
                    <th class="right">$0.00</th>
                    <th class="right">{{ totalGoalMtdLocation(0) | currency }}</th>
                    <th class="right">{{ totalGoalMtd | currency }}</th>
                </tr>
                <tr v-for="[cdiLocationID, cdiLocation] in cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID">
                    <td>{{ cdiLocation }}</td>
                    <td class="right">$0.00</td>
                    <td class="right">$0.00</td>
                    <td class="right"
                        :class="(totalSalesMtd(cdiLocationID) < totalGoalMtdLocation(cdiLocationID)) ? 'bg-danger': ''">
                        {{ totalSalesMtd(cdiLocationID) | currency }}
                    </td>
                    <td class="right">{{ totalGoalMtdLocation(cdiLocationID) | currency }}</td>
                </tr>
                </tbody>
            </table>
        </div>
        </div>
    </div>
</template>

<script>

import cdiLocationCache from "@/cache/cdiLocation.cache";
import {store} from "@/store/Report.store";

export default {
    name: 'Result',
    props: {
        'goalData': {
            default: false
        },
    },
    data() {
        return {
            state: store.state,
            cdiLocationCache
        }
    },
    methods: {
        runReport: function () {
            this.populateFilters(null)
            store.runReport('table', 'slot1');
        },
        populateFilters: function (filters = null) {
            if (!filters) {
                filters = this.state.optionFields
            }
            let filterEntries = Object.entries(filters)

            this.filters = {};
            for (let i = 0; i < filterEntries.length; i++) {
                this.filters[filterEntries[i][0]] = filterEntries[i][1].val;
            }
        },
        isLocation() {
            if (this.goalData[1] && this.goalData[1]['is_location'])
                return true;
        },
        totalGoalMtdLocation(locationId) {
            if (locationId !== 0) {
                const locationGoal = this.goalData.find(goal => goal.cdi_location_id === locationId);
                return locationGoal ? parseInt(locationGoal.location_total) : 0;
            } else {
                return this.goalData
                    .filter(goal => goal.cdi_location_id !== 0)
                    .reduce((total, goal) => total + parseInt(goal.day_sales), 0);
            }
        },
        totalSalesMtd($locationId) {
            let total = 0;
            if ($locationId != 0) {
                for (let i = 0; i < this.goalData.length; i++) {
                    if (this.goalData[i]['cdi_location_id'] == $locationId) {
                        total += parseInt(this.goalData[i]['day_sales']);
                    }
                }
                return total;
            }
        }
    },
    computed: {
        lastItem() {
            return this.goalData[this.goalData.length - 1];
        },
        totalGoalMtd() {
            const {cumulative_total_sales_mtd: total} = this.goalData[0];
            return total;
        }

    },
    mounted() {
        this.runReport();
    }
}

</script>
